:root {
  --color-background: #1f2123;
  --color-primary: #20e3b2;
  --color-secondary: #272a2d;
  --color-accent: #29ffc6;
  --color-gray: #efefef;
  --color-headings: #000;
  --color-body: #918ca4;
  --color-body-darker: #5c5577;
  --color-border: #ccc;
  --border-radius: 10px;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

/* Typography */
::selection {
  background: var(--color-primary);
  color: #fff;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

img {
  width: 100%;
}

body {
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 2rem;
  line-height: 1.5;
  color: var(--color-body);
  background-color: var(--color-background);
}
.container {
  padding: 10px;
}

h1,
h2,
h3,
h4 {
  color: var(--color-primary);
  margin: 0;
  line-height: 1.1;
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 1.3;
}

h4 {
  font-size: 2.2rem;
  font-weight: 600;
  line-height: 1.3;
}

p {
  font-size: 1.8rem;
  margin: 0;
  color: rgb(225, 225, 225);
  line-height: 3rem;
}

a {
  color: #fff;
  text-decoration: none;
}

ul {
  padding-left: 0;
}

li {
  list-style-type: none;
  margin-left: 0;
  background-color: var(--color-background);
  margin: 15px 10px;
  padding: 10px;
  /* border: 1px solid var(--color-primary); */
  border-radius: var(--border-radius);
  text-align: center;
}

@media screen and (min-width: 768px) {
  .container {
    padding: 0 5%;
  }
  h1 {
    font-size: 6rem;
  }
  h3 {
    font-size: 4rem;
  }
  h4 {
    font-size: 3rem;
  }
  p {
    font-size: 2.2rem;
    line-height: 3.5rem;
    width: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    padding: 0 10%;
  }
  h1 {
    font-size: 6rem;
  }
  p {
    font-size: 2.2rem;
    line-height: 3.5rem;
  }
}

/* NAVBAR */
nav {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--color-background);
  width: 100vw;
  height: 60px;
  z-index: 100;
  box-shadow: 0 0 20px 0px var(--color-primary);
}

nav .nav-logo {
  color: #fff;
  font-size: 3rem;
  line-height: 60px;
  font-weight: bold;
  /* font-family: "Press Start 2P"; */
}

.nav-items-container {
  float: right;
  margin-right: 20px;
}

.nav-item {
  display: inline-block;
  line-height: 60px;
  margin: 0 20px;
  text-transform: uppercase;
  font-size: 1.8rem;
}

.nav-resume {
  margin-left: 50px;
  padding: 8px 20px;
  border: 1px solid var(--color-primary);
  border-radius: 10px;
  font-size: 1.8rem;
  text-transform: uppercase;
}
.nav-resume:hover {
  background-color: var(--color-primary);
  color: var(--color-background);
  transition: 0.3s;
  /* font-weight: 500; */
}

.nav-item:active,
.nav-item:hover {
  color: var(--color-primary);
  transition: 0.5s;
}

.checkbtn {
  font-size: 3rem;
  color: #fff;
  float: right;
  line-height: 60px;
  margin-right: 40px;
  cursor: pointer;
  display: none;
}

#check {
  display: none;
}

@media (max-width: 858px) {
  nav {
    display: none;
  }
  nav .nav-logo {
    display: none;
  }
  .nav-items-container {
    float: right;
    margin-right: 0px;
  }
  .nav-item {
    margin: 0 10px;
    font-size: 1.8rem;
  }
}

.header-container {
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
}

.header-background {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  opacity: 0.5;
  z-index: -1;
}

.header-image {
  /* margin-top: 5rem; */
  margin-bottom: 4rem;
  border-radius: 30px;
  width: 50%;
  box-shadow: 0 0 20px 0px var(--color-primary);
}
.header-info h1 {
  font-family: "Press Start 2P";
}

.header-intro {
  margin: 4rem 0;
}

.intro-button {
  border: 1px solid var(--color-primary);
  border-radius: var(--border-radius);
  padding: 10px 20px;
  text-align: center;
  width: 70%;
  margin: 30px 0;
}

.intro-button:hover {
  background-color: var(--color-primary);
  color: var(--color-background);
  font-weight: 600;
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 1024px) {
  .header-container {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 100vh;
  }
  .header-image {
    width: 30%;
    box-shadow: 0 0 50px 5px var(--color-primary);
  }
  .header-info {
    width: 45%;
  }
  .intro-button {
    width: 100%;
  }
}

/* ABOUT ME */
.about-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;
}

.about-header {
  margin-top: 5rem;
  margin-bottom: 4rem;
}

.about-info h4 {
  margin-bottom: 1rem;
}

.about-image {
  display: none;
}

@media screen and (min-width: 1024px) {
  .about-header {
    margin-bottom: 6rem;
  }
  .about-container {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 5%;
  }
  .about-image {
    display: block;
    width: 40%;
  }
  .about-info {
    width: 55%;
  }
}

/* SKILLS */
.skills-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;
}

.skills {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.skills-row {
  display: flex;
  flex-direction: column;
}
.skills-col {
  display: flex;
  flex-direction: row;
}

.skill-icon {
  background-color: var(--color-secondary);
  padding: 10px;
  border-radius: 20px;
  opacity: 0.5;
  width: 125px;
}

.skill-icon:hover {
  opacity: 1;
  box-shadow: 0 0 20px 0px var(--color-primary);
}

@media screen and (min-width: 1024px) {
  .skills-container {
    padding: 0 5%;
    /* margin-bottom: 20rem; */
  }
  .skills-row {
    display: flex;
    flex-direction: row;
    margin-top: 5rem;
  }
  .skills-col {
    display: flex;
    flex-direction: row;
  }
  .skills-col li {
    margin: 0 5rem;
  }
}

/* RESUME */
.resume-container {
  background-color: #fff;
  width: 100%;
  padding: 20px;
  margin-bottom: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.resume-container p {
  margin: 10px 0;
  width: 100%;
  color: var(--color-background);
}
.resume-button {
  border: 1px solid var(--color-primary);
  border-radius: var(--border-radius);
  padding: 10px 20px;
  text-align: center;
  color: var(--color-background);
}
.resume-button:hover {
  border: 1px solid var(--color-background);
  background-color: var(--color-background);
  color: var(--color-primary);
}

@media screen and (min-width: 768px) {
  .resume-button {
    margin: 0 10px;
  }
}

@media screen and (min-width: 1024px) {
  .resume-container {
    padding: 50px;
  }
  .resume-container p {
    margin: 10px 0;
    width: 50%;
    color: var(--color-background);
  }
  .resume-button {
    margin: 0 10px;
  }
}

/* Road Map */
.project-container {
  margin-top: 50px;
}
.roadmap--container {
  padding-bottom: 100px;
  margin: 0 auto;
  max-width: 100vw;
}

.roadmap--top-section {
  text-align: center;
  padding: 4rem 0;
  max-width: 600px;
  margin: 0 auto;
}

.roadmap--top-section h2 {
  color: #fff;
}

.roadmap--top-section p {
  color: #d2d0db;
}

.timeline {
  position: relative;
  min-height: 150px;
}

.timeline__line {
  position: absolute;
  left: calc(50% - 1px);
  width: 2px;
  top: -50px;
  bottom: -50px;
  background: #fff;
  display: none;
}

.timeline__line::before,
.timeline__line::after {
  position: absolute;
  display: block;
  content: "";
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background: #fff;
  left: 50%;
  transform: translateX(-50%);
}

.timeline__line::after {
  bottom: 0;
}

.timeline__section {
  display: flex;
  opacity: 0;
  transform: translateX(-100%);
  transition: 600ms ease;
  position: relative;
  z-index: 1;
  margin: 50px 0;
  padding: 1rem;
  border-radius: 1rem;
  align-items: center;
  min-height: 300px;
  background: var(--color-secondary);
}

.timeline__section:nth-child(odd) {
  flex-direction: row-reverse;
  transform: translateX(100%);
}

.timeline__section-bead {
  position: absolute;
  display: none;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background: #fff;
  left: 50%;
  top: 20%;
  transform: translateX(-50%);
}

.timeline__section-content {
  /* width: calc(50% - 2rem); */
}

.timeline__section-content > h3 {
  margin-bottom: 20px;
  color: #fff;
}

.timeline__section-content > p {
  margin-bottom: 10px;
  color: #d2d0db;
}

.show-me:nth-child(n) {
  transform: none;
  opacity: 1;
}

.timeline__section-link:hover {
  color: var(--color-primary);
  cursor: pointer;
}

@media screen and (min-width: 1024px) {
  .timeline__section {
    background: none;
  }

  .timeline__line {
    z-index: 2;
  }

  .timeline__section-content {
    width: calc(50% - 2rem);
  }

  .roadmap--container {
    max-width: 70vw;
  }

  .timeline__section-bead {
    display: block;
  }
}

/* SOCIAL BANNER */
.social-banner {
  display: none;
}

@media screen and (min-width: 1024px) {
  .social-banner {
    position: fixed;
    bottom: 0;
    left: 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .social-line {
    border-left: 2px solid var(--color-primary);
    height: 10rem;
    margin-top: 20px;
    opacity: 0.6;
  }
  .social-banner-icon {
    margin-bottom: 15px;
    width: 40px;
    height: 40px;
    opacity: 0.6;
  }
  .social-banner-icon:hover {
    margin-bottom: 20px;
    opacity: 1;
  }
}

.email-banner {
  display: none;
}

@media screen and (min-width: 1024px) {
  .email-banner {
    position: fixed;
    bottom: 0;
    right: 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .email-line {
    border-left: 2px solid var(--color-primary);
    height: 10rem;
    margin-top: 20px;
    opacity: 0.6;
  }
  .email-banner-text {
    writing-mode: vertical-rl;
    letter-spacing: 3px;
    color: var(--color-primary);
    opacity: 0.6;
  }
  .email-banner-text:hover {
    opacity: 1;
    margin-bottom: 5px;
  }
}

/* FOOTER */
.footer {
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 100px 20px 50px 20px;
}
.footer p {
  margin: 10px 0 40px 0;
  color: var(--color-background);
}
.footer-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-form input {
  background-color: var(--color-gray);
  border: 0;
  margin: 10px 0;
  padding: 15px 10px;
  font-size: 1.6rem;
  width: 100%;
  border-radius: 10px;
  color: var(--color-background);
}
.footer-form input::placeholder {
  color: #ccc;
}
textarea,
input {
  outline: none;
}
.footer-form input:focus {
  box-shadow: 0 0 10px 0px var(--color-primary);
}

.footer-form textarea {
  background-color: var(--color-gray);
  border: 0;
  margin: 10px 0;
  padding: 15px 10px;
  font-size: 1.6rem;
  width: 100%;
  border-radius: 10px;
  color: var(--color-background);
}
.footer-form textarea::placeholder {
  color: #ccc;
}
.footer-form textarea:focus {
  box-shadow: 0 0 10px 0px var(--color-primary);
}

.footer-form button {
  background-color: #fff;
  padding: 15px 10px;
  width: 100%;
  border: 1px solid var(--color-primary);
  border-radius: 10px;
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--color-primary);
}

.footer-form button:hover {
  background-color: var(--color-primary);
  color: #fff;
}

.footer-socials {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
}

.footer-icon {
  width: 40px;
  height: 40px;
  opacity: 0.6;
  margin: 0 10px;
}
.footer-icon:hover {
  margin-bottom: 20px;
  opacity: 1;
}

.footer-contact {
  margin-top: 10px;
}
.footer-contact a {
  margin: 0;
  color: var(--color-background);
  font-size: 1.8rem;
}

@media screen and (min-width: 1024px) {
  .footer {
    padding: 100px 20px 100px 20px;
  }
  .footer-socials {
    display: none;
  }
  .footer-contact {
    display: none;
  }
  .footer-info {
    width: 45%;
  }
  .footer-form {
    width: 40%;
  }
}
